import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M172.906 48.414 C 60.456 68.748,9.019 201.789,78.141 293.521 C 137.200 371.898,258.673 373.387,319.578 296.482 C 372.123 230.132,361.630 131.977,296.482 80.432 C 262.551 53.586,213.946 40.992,172.906 48.414 M225.314 118.943 C 268.892 128.179,278.536 190.794,239.117 208.569 C 231.233 212.125,230.038 208.173,249.875 244.141 L 266.894 275.000 250.244 274.983 L 233.594 274.966 218.569 246.467 L 203.545 217.969 188.491 217.522 L 173.438 217.074 173.438 246.037 L 173.438 275.000 159.375 275.000 L 145.313 275.000 145.313 196.094 L 145.313 117.188 181.173 117.188 C 201.746 117.188,220.563 117.936,225.314 118.943 M173.438 167.188 L 173.438 192.494 194.141 191.916 C 224.502 191.067,232.794 185.804,232.807 167.372 C 232.820 148.737,224.389 143.305,194.141 142.459 L 173.438 141.881 173.438 167.188"
          fill="#64FFDA"
        />
      </g>
    </g>
  </svg>
);

export default IconLoader;
